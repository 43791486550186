.AuthForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--primary);
}

.AuthForm h1 {
    text-align: center;
    font-weight: 800;
    font-size: 2.5rem;
    color: var(--text-color);
    margin-bottom: 1em;
}

.AuthForm form {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 400px;
}

.AuthForm input[type="email"],
.AuthForm input[type="password"] {
    padding: 10px;
    border: 2px solid var(--secondary-highlight);
    border-radius: 8px;
    background-color: var(--primary);
    color: var(--text-color);
}

.AuthForm input[type="email"]:focus,
.AuthForm input[type="password"]:focus {
    outline: none;
    border-color: var(--text-color-weak);
}

.AuthForm button[type="submit"] {
    padding: 10px;
    background-color: var(--secondary);
    color: var(--text-color);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

.AuthForm button[type="submit"]:hover {
    background-color: var(--secondary-highlight);
}

.AuthForm div span {
    color: var(--text-color-weak);
    font-size: 1.0em;
}

.AuthForm div a {
    color: var(--text-color);
    text-decoration: none;
}

.AuthForm .OAuthButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
}

.OAuthButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 10px;
    padding: 7px 18px;
    border-radius: 10px;
    border: 2px solid var(--secondary-highlight);
    color: var(--text-color);
    text-decoration: none;
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}

.logoutButton {
    background-color: var(--primary);
    color: var(--text-color);
    border: 2px solid var(--secondary-highlight);
}

.logoutButton:hover {
    background-color: var(--secondary);
    color: var(--text-color-highlight);
}

.OAuthButton:hover {
    background-color: var(--secondary);
    color: var(--text-color-highlight);
}

.OAuthButton img {
    width: 24px;
    height: 24px;
}

.AuthForm {
    width: 400px;
}

.OAuthButton {
    width: 400px;
    justify-content: center;
}

.opposite {
    margin-top: 1em;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 1em 0;
    border-bottom: 1px solid var(--secondary);
    width: 100%;
}


@media (max-width: 768px) {
    .AuthForm form {
        width: calc(100vw - 2em);
        max-width: 400px;
    }

    .OAuthButton {
        max-width: 400px;
        width: calc(100vw - 2em);
    }

}