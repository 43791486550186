
body {
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--primary) !important;
  color: var(--text-color) !important;
  height: 100vh;
}

.App {
  text-align: center;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--primary);
  border-left: 1px solid var(--primary-highlight);
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #AAA;
}

.SearchbarResults::-webkit-scrollbar {
  width: 10px;
  background-color: var(--primary);
}

.SearchbarResults::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 5px;
}

.SearchbarResults::-webkit-scrollbar-thumb:hover {
  background-color: var(--secondary);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tooltip {
  background-color: var(--secondary) !important;
  color: var(--text-color) !important;
}

.hidden {
  display: none;
}
