.LandingPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20vh;
    margin-bottom: 5vh;
    gap: 2rem;
    animation: fadeIn 1s ease-in-out;
}

.LandingPage h1 {
    font-weight: 800;
    font-size: 3.0rem;
    text-align: center;
    margin-bottom: 0;
}

.LandingPageSubtitle {
    font-weight: 500;
    font-size: 1.5em;
    text-align: center;
    margin-bottom: 1rem;
    color: var(--text-color-weak);
}

.LandingPageHeaders {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    /* margin-bottom: 1rem; */
}

.ShowcaseRows {
    width: 70vw;
    margin-top: 2rem;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media only screen and (max-width: 768px) {
    .LandingPage {
        margin-top: 10vh;
        margin-bottom: 109px;
    }

    .LandingPage .TopbarSearchbar {
        margin: 1rem;
    }

    .LandingPage h1 {
        margin: 2rem;
        margin-bottom: 0;
    }

    .LandingPageSubtitle {
        margin: 2rem;
        margin-top: 0;
        margin-bottom: 0;
    }

    .LandingPageHeaders {
        margin-bottom: 0rem;
    }

    .LandingPage .TopbarSearchbar {
        width: 80%;
    }
    .ShowcaseRows {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 5px;
    }
}
