.Menu {
    flex-direction: column;
    position: fixed;
    width: 100%;
    left: 0;
    top: 68px;
    opacity: 0;
    background-color: var(--primary);
    border-bottom: 1px solid var(--secondary);
    transform: translateY(-300px);
    /* TODO: add transform */
    /* transition: transform 0.1s ease-in-out, opacity 0.1s ease-in, opacity 0.1s ease-out; */
}

.Menu.open {
    transform: translateY(0%);
    box-shadow: 0 25px 50px rgba(128, 128, 128, 0.125);
    opacity: 1;
}

.Menu-top-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.0rem;
    gap: 0.5rem;
    padding-bottom: 0px;
    height: 100%;
}

.Menu-middle-section {
    display: flex;
    height: 100%;
    flex-direction: column;
    padding: 1.0rem 1.0rem;
    padding-top: 0px;
    gap: 1.0rem;
}

.Menu-link {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 10px;
    padding: 7px 18px;
    border-radius: 10px;
    border: 2px solid var(--secondary-highlight);
    color: var(--text-color);
    text-decoration: none;
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
    width: 100%;
    justify-content: center;
}

.Menu-link:hover {
    background-color: var(--secondary);
    color: var(--text-color-highlight);
}
