.Setting {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    justify-content: end;
  }

.setting-switch .form-check-input {
    width: 2.5rem;
    height: 1.5rem;
    border: 0;
    background-color: var(--secondary);
    cursor: pointer;
}

.setting-switch .form-check-input:checked {
    background-color: var(--accent);
    box-shadow: 0px 0px 100px 5px var(--accent) !important;
}

.setting-switch .form-check-input:focus {
    box-shadow: none;
}
