
.SkeletonRowText,
.SkeletonRowButton,
.SkeletonRowRectButton {
    background: linear-gradient(90deg, rgba(200, 200, 200, 0.2), rgba(255, 255, 255, 0.4), rgba(200, 200, 200, 0.2));
    background-size: 200% auto;
    animation: shine 2.0s infinite;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
  
.SkeletonRowInfo {
    padding-left: 0.5em;
    width: calc(30vw - 130px);
}
  
.SkeletonRowText {
    height: 20px;
    margin: 0;
}
  
.SkeletonRowText.title {
    height: calc(28.8px - 0.5em);
    max-width: calc(1em * 20);
    width: 90%;
    margin-top: 0.75em;
    margin-bottom: 0.5em;
}
  
.SkeletonRowText.artist {
    height: calc(24px - 0.5em);
    max-width: calc(1em * 8);
    width: 40%;
    margin-bottom: 0.75em;
}
  
.SkeletonRowButton {
    width: 2em;
    height: 2em;
    border-radius: 50%;
}
  
.SkeletonRowRectButton {
    width: 114px;
    height: 44px;
}

.SkeletonRowRectButton.ext {
    box-shadow: 80px 0px 100px 0px var(--shadow-weak);
}

@keyframes shine {
    to {
        background-position: -200% center;
    }
}

@media only screen and (max-width: 768px) {
    .SkeletonRowInfo {
        width: calc(100vw - 170px - 32px - 16px - 24px);
        padding-left: 0.25em;
    }
    .SkeletonRowRectButton.sim {
        width: 58px;
        height: 46px;
    }
    .SkeletonRowRectButton.ext {
        padding: 4px;
        min-width: 58px;
        width: 58px;
    }
    .SkeletonRowText.title {
        height: calc(25.97px - 0.5em);
    }
    .SkeletonRowText.artist {
        height: calc(24px - 0.5em);
    }
}
