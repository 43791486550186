.Song {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100vh;
  width: 100vw;
  background-color: var(--primary);
}

.SongCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 33vw;
  background-color: var(--secondary);
  position: fixed;
  padding-top: calc(68px + 1em); /* 68px height of topbar */
}

.SongCardInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4.5em 3.0em;
  max-width: 100%;
  border-radius: 15px;
  transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.SongCardInner:hover {
  background-color: var(--primary);
}

.SongCard.playing .SongCardInner {
  background-color: var(--primary);
}

.SongCover img {
  transition: transform 0.3s ease;
  max-width: 100%;
}

@keyframes ripple {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.02);
    }
}

.SongCover {
  width: 400px;
  max-width: 25vw;
}

.SongCover img {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 2.5%;
  box-shadow: 0px 0px 40px 40px rgba(46, 46, 46, 0.75);
}

.SongCardInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
  max-width: 400px;
}

.SongCardInfo h2 {
  font-size: 2.0rem;
}

.SongCardInfo h4 {
  font-size: 1.5rem;
}

.SongSimilar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 67vw;
  background-color: var(--primary);
  margin-left: 33vw;
}

.SongSimilarHeaderText {
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: 0.5em;
}

.playlist-guided-text {
  color: var(--text-color-weak);
  font-size: 1.25em;
}

.SongSimilar h2 {
  margin: 0;
  font-size: 2.0rem;
}

.SongSimilarHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 6rem;
  margin-bottom: 2.5rem;
  margin-left: 3.25em;
  padding-right: 5.0em;
}

.SongSimilarSettings {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  justify-content: end;
}

.SongSimilarSettings span {
  font-size: 20px;
  color: var(--text-color);
}

.Tooltip {
  background-color: var(--text-color);
  color: var(--secondary);
  padding: 5px;
  border-radius: 3px;
  font-size: 14px;
  text-align: center;
  z-index: 1000000;
}

.primary {
  background-color: var(--secondary);
  color: var(--text-color);
  border: none;
}

.primary:focus {
  background-color: var(--secondary);
  color: var(--text-color);
  box-shadow: none !important;
  outline: none !important;
}

.Slider {
  position: relative;
  width: 100%;
  height: 4px;
  margin-left: 8px;
}

.SliderRail {
  background: var(--secondary);
  height: 8px;
  position: relative;
}

.Handles .Handle {
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: var(--text-color);
  border-radius: 50%;
  top: -11px;
  z-index: 1;
}

.Tracks .Track {
  position: absolute;
  height: 8px;
  background-color: var(--accent);
  top: -1px;
}

.flexrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.add-button {
  display: flex;
  font-size: 1.5em;
  width: 2em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s ease-in-out;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 0.5em;
  border-top-left-radius: 0.5em;
  user-select: none;
}

.add-button:hover {
  color: var(--text-color-highlight);
  box-shadow: 0px 0px 2px 0px var(--shadow-weak);
  background-color: var(--secondary);
}

.SongRow.playing .add-button,
.SongRow:hover .add-button {
  opacity: 1;
  z-index: 1;
  pointer-events: all;
  background-color: var(--secondary);
}

.SearchbarResult .add-button {
  opacity: 1 !important;
  pointer-events: all !important;
  border-radius: 0;
  transition: 0.0s ease-in-out;
  transition: color 0.2s ease-in-out;
}

.SearchbarResult .add-button:hover {
  box-shadow: none !important;
  background-color: inherit !important;
}

.similar-button {
  background: linear-gradient(45deg, var(--soft-gradient-start), var(--soft-gradient-end));
  color: var(--text-color);
  padding: 7px 18px;
  border-radius: 10px;
  border: none;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border: 2px solid var(--secondary-highlight);
  transition: color 0.2s ease-in-out;
  font-size: 1.1em;
  font-weight: 500;
  height: 46px;
}

.similar-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, var(--soft-gradient-hover-start), var(--soft-gradient-hover-end));
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
  pointer-events: none;
  border-radius: inherit;
  z-index: -1;
}

.similar-button:hover::before {
  opacity: 1;
}

.similar-button:hover {
  color: var(--text-color-highlight);
}


.truncate-card,
.truncate-row {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-row {
  max-width: calc(50vw - 285px);
}

.truncate-card {
  width: calc(15vw);
  max-width: 100%;
}

.truncate-row-single {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(50vw - 100px);
}

.SongRows {
  width: 85%;
  padding-top: 1em;
}

.SongRow {
  display: flex;
  position: relative;
  z-index: 1;
  border-image: linear-gradient(to right, transparent, var(--secondary-highlight), transparent);
  border-image-slice: 1;
  border-width: 0 0 1px 0;
  border-style: solid;
}
.SongRowBody {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
  padding-right: 1.5em;
}

.SongRow:hover .SongRowBody,
.SongRow.playing .SongRowBody {
  background-color: var(--secondary);
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  color: var(--text-color-highlight);
}

.SongRowInfo {
  padding-left: 0.5em;
}

.SongRow h4 {
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.SongRow p {
  margin-top: 0;
  margin-bottom: 0.5rem;
  height: 1.5rem;
}

.ext-button {
  background-color: #191414;
  border: 2px solid var(--secondary-highlight);
  border-radius: 10px;
  padding: 0;
  padding: 7px;
  min-width: 113px;
  height: 46px;
  color: white;
  box-shadow: 80px 0px 100px 0px var(--shadow-extra-weak);
  transition: 0.4s, border 0.0s;
}

.ext-button:hover {
  box-shadow: 0px 0px 100px 20px #1db95488 !important;
  background-color: #191414;
  color: white;
}

.SongRow:hover .ext-button {
  box-shadow: 80px 0px 100px 15px var(--shadow-weak);
}

.inMixingList .ext-button,
.inMixingList:hover .ext-button {
  box-shadow: none !important;
}

.inMixingList .ext-button:hover {
  box-shadow: none !important;
}

.ext-button img {
  width: auto;
  height: 100%;
}

.pointer {
  cursor: pointer;
}

.PlayPauseButton {
  transition: 0.2s ease-in-out;
}

.PlayPauseButton:hover {
  color: var(--text-color-highlight);
}

.songcard-add-button {
  width: 112px;
  height: 48px;
}

.songcard-add-button:hover {
  background-color: #555;
}

.spotify-add-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transform: scale(1.1);
  margin-top: 3em;
  gap: 1em !important;
}

.SongCard .PlayPauseButton {
  font-size: 3.5em;
}

@media only screen and (max-width: 768px) {
  .Song {
    flex-direction: column;
  }
  .SongCard {
    width: 100vw;
    height: 100%;
    padding-bottom: 1em;
    position: relative;
  }
  .SongCardInner {
    padding: 3.0em 1.5em;
  }
  .SongCover {
    max-width: 100%; 
  }
  .truncate-card {
    width: 75vw;
    max-width: 100%;
  }
  .truncate-row {
    min-width: calc(100vw - 205px);
  }
  .truncate-row-single {
    width: calc(70vw - 40px);
    max-width: 100%;
  }
  .SongSimilar {
    margin-left: 0;
    width: 100vw;
    height: 100%;
    padding-top: 4em;
    padding-bottom: 1em;
    max-width: 100%;
  }
  .SongRow {
    margin-left: 0;
  }
  .SongRowBody {
    padding-right: 0.75em;
  }
  .add-button {
    opacity: 1;
    pointer-events: all;
  }
  .SongRowInfo {
    padding-left: 0.25em;
    padding-right: 0.5em;
  }
  .SongRow {
    width: 100%;
  }
  .SongRows {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .SongCardInfo {
    width: 90vw;
  }
  .SongSimilarHeader {
    flex-direction: column;
    margin-left: auto;
    margin-top: 0;
    padding: 0;
  }
  .flexrow {
    gap: 5px;
  }
  .SongSimilarSettings {
    width: auto;
    padding-right: 10px;
    padding-left: 10px;
    gap: 10px;
  }

  .similar-text {
    font-size: 0;
  }

  .similar-icon {
      font-size: 1em;
  }

  .SongRow .spotify-icon {
    content: url("/public/spotify_green_icon.png");
  }

  .SongRow .ext-button{
    padding: 4px;
    min-width: 58px;
  }

  .SongSimilarHeaderText {
    flex-direction: column;
    gap: 0;
    align-items: center;
    margin-bottom: 2.0em;
  }

  .playlist-guided-text {
    height: 1.0em;
  }
}
