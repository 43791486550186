.SkeletonCover,
.SkeletonText,
.SkeletonButton {
  background: linear-gradient(90deg, rgba(200, 200, 200, 0.2), rgba(255, 255, 255, 0.4), rgba(200, 200, 200, 0.2));
  background-size: 200% auto;
  animation: shine 2.0s infinite;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.SkeletonCard {
  width: 400px;
  max-width: 100%;
}

.SkeletonCover {
  width: 400px;
  max-width: 25vw;
  aspect-ratio: 1/1;
  border-radius: 2.5%;
}

.SkeletonText {
  width: 70%;
  height: 20px;
}

.SkeletonText.title {
  width: 80%;
  height: 38px;
  margin-bottom: 0.5em !important;
  padding-bottom: 0.0em;
}

.SkeletonText.artist {
  width: 40%;
  height: 25px;
  margin-top: 4px;
}

.SkeletonButton {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

@keyframes shine {
  to {
    background-position: -200% center;
  }
}

.SkeletonCard::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
}

.SkeletonButton.rectangular {
  border-radius: 10px;
  width: 114px;
  height: 48px;
}

@media only screen and (max-width: 768px) {
  .SkeletonCard {
    height: 100%;
    padding-top: calc(68px + 1em); /* 68px height of topbar */
    padding-bottom: 4em;
    position: relative;
  }

  .SkeletonCover {
    max-width: 100%;
  }
}
