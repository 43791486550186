.MusicPlayer {
    min-width: 350px;
    height: 55px;
    background-color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: 0 1px 5px 1px rgba(128, 128, 128, 0.25);
    padding: 1.5em;
    gap: 1em;
}

.MixingContainer {
    background-color: var(--primary);
    border-radius: 15px 15px 0 0;
    box-shadow: 0 0 5px 0px rgba(128, 128, 128, 0.25);
    height: 100%;
}

.MixingList {
    max-height: auto;
    transition: 0.25s ease;
    overflow: hidden;
}

.MixingList.collapsed {
    max-height: 0;
}

.MusicPlayerContainer {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 58%;
    z-index: 1000;
}

.MixingHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75em;
}

.MixingContainer .expand-btn {
    background-color: var(--primary);
    color: var(--text-color);
    border: none;
    cursor: pointer;
    border-radius: 5px; 
}

.MixingContainer .expand-btn:disabled {
    cursor: default;
    opacity: 0.5;
}

.list-count {
    align-self: center;
    margin: 0;
    pointer-events: none;
    transition: 0.3s ease-in-out;
}

.list-count.hidden {
    opacity: 0;
}

.icon-transition {
    transition: transform 0.2s ease;
}

.rotate-chevron {
    transform: rotate(-180deg);
}

.VolumeIconContainer {
    width: 24px;
    text-align: center;
}

.ControlsContainer {
    display: flex;
    align-items: center;
    gap: 1em;
}

.VolumeContainer {
    display: flex;
    align-items: center;
    gap: 1em;
}

.Scrubber {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    border-radius: 4px;
    background: gray;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s, background 0.2s;
    margin: 1em;
}

.Scrubber::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: var(--text-color);
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s;
}

.Scrubber:hover {
    opacity: 1;
}

.Scrubber:hover::-webkit-slider-thumb {
    opacity: 1;
}

.VolumeSlider {
    -webkit-appearance: none;
    width: 100px;
    height: 8px;
    border-radius: 4px;
    background: var(--primary);
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
}

.VolumeSlider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: var(--accent);
    opacity: 0;
    cursor: pointer;
    transition: opacity 0.2s;
}

.VolumeSlider:hover::-webkit-slider-thumb {
    opacity: 1;
}

.VolumeSlider:hover {
    opacity: 1;
}

@media (max-width: 768px) {
    .VolumeSlider {
        width: 80px;
    }
    .MusicPlayerContainer {
        width: 100%;
        left: 0;
        transform: translateX(0);
        bottom: 0;
    }
    .MusicPlayer {
        border-radius: 0;
    }
}