.UserManagement {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 68px);
    width: 400px;
    margin-top: calc(68px);
    gap: 1em;
    background-color: var(--primary);
}

.UserManagement h1 {
    text-align: center;
    font-weight: 800;
    font-size: 2.5rem;
    color: var(--text-color);
}

.accountStatus {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-bottom: 1em;
}

.UserManagement button {
    padding: 10px;
    background-color: var(--secondary);
    color: var(--text-color);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease-in-out color 0.3s ease-in-out;
}

.UserManagement button:hover {
    background-color: var(--secondary-highlight);
}

.disabledInput {
    padding: 10px;
    border: 2px solid var(--secondary-highlight);
    border-radius: 8px;
    background-color: var(--primary);
    color: var(--text-color);
    opacity: 0.6;
}

.UserManagement .wideInput {
    width: 100%;
    max-width: 400px;
}

.UserManagement .styledButton {
    background-color: var(--secondary);
    color: var(--text-color);
    cursor: pointer;
    transition: background 0.3s ease-in-out color 0.3s ease-in-out;
    height: 44px;
}

.UserManagement .styledButton:hover {
    background-color: var(--warning);
    color: white;
}

@media (max-width: 768px) {
    .UserManagement .wideInput {
        width: calc(100vw - 2em);
    }

    .UserManagement {
        width: calc(100vw - 2em);
    }
}