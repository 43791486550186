.notification {
    position: fixed;
    bottom: 0px;
    text-align: center;
    width: 100vw;
    background-color: var(--primary-highlight);
    color: white;
    padding: 16px;
    border-radius: 4px;
    font-size: 1.2em;
    z-index: 1010;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.notification.show {
    opacity: 1;
    transform: translateY(0);
}

.notification.error {
    background-color: var(--warning);
}

.notification.success {
    background-color: var(--success);
}
