.SubscribeCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5em;
    background-color: var(--primary);
    border-radius: 10px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease-in-out;
    border: 1px solid var(--secondary-highlight);
}

.SubscribeCard h2 {
    font-size: 1.5rem;
    color: var(--text-color);
    margin-bottom: 1em;
}

.SubscribeCard p {
    font-size: 1.0rem;
    color: var(--text-color-weak);
    line-height: 0.75em;
}

.SubscribeCardContent {
    margin-bottom: 1em;
    align-self: stretch;
}

.SubscribeCard button {
    background-color: var(--primary);
    border: 2px solid var(--secondary-highlight);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out, color 0.3s ease-in-out;
    font-size: 1.0rem;
    height: 2.5rem;
}

.ButtonError {
    background-color: var(--warning) !important;
    border: 2px solid var(--warning) !important;
}

.SubscribeCard button:hover {
    background-color: var(--secondary);
    box-shadow: 0px 2px 8px var(--shadow-extra-weak);
}
