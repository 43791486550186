.equalizer-bar {
    display: inline-block;
    width: 4px;
    height: 0.75em;
    background-color: var(--text-color);
    margin-left: 0px;
    animation: playAnimation 1.0s infinite alternate;
    border-radius: 2px;
}

.equalizer.flexrow {
    gap: 1px !important;
}

@keyframes playAnimation {
    0%, 100% {
        transform: scaleY(1.0);
    }
    50% {
        transform: scaleY(0.66);
    }
}
