
.Topbar {
    display: flex;
    width: calc(100vw - 12px);
    height: 68px;
    padding: 8px 16px;
    background-color: var(--primary);
    color: var(--text-color);
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #00000000;
    transition: border-color 0.3s ease;
}

.Topbar-bottom-border {
    border-color: var(--secondary-highlight);
}

.Topbar-logotext {
    font-size: 1.5rem;
    font-weight: 800;
    margin-left: 10px;
    margin-bottom: 0;
    color: var(--text-color);
    text-decoration: none;
}

.TopbarSearchbar {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 50vw;
}

.SearchbarResults {
    width: 100%;
    border: 1px solid var(--secondary);
    border-top: 0px;
    z-index: 1000;
    border-radius: 0px;
}

.SearchbarResult {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    background-color: var(--primary);
    border: 0px;
    color: var(--text-color);
    padding: 0.5rem 0;
}

.SearchbarResult:hover {
    color: var(--text-color);
    background-color: var(--secondary);
}

.dark-input {
    background-color: var(--secondary);
    color: var(--text-color);
}

.dark-input::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.dark-input:focus {
    background-color: var(--secondary);
    color: var(--text-color);
}

.search-icon {
    border: none !important;
}

.search-input {
    border: none !important;
}

.artist-result {
    margin-left: 1.0rem;
    font-weight: 600;
}

.custom-search-input,
.custom-search-input .form-control,
.custom-search-input .input-group-text {
    transition: 0.3s ease;
    border-radius: 15px;
    border-bottom: 1px solid #00000000;
}

.custom-search-input.results-shown {
    border-bottom: 1px solid var(--secondary);
}

.custom-search-input .form-control:focus,
.custom-search-input .input-group-text:focus-within {
    box-shadow: none !important;
    border-color: inherit !important;
    outline: none !important;
}
.custom-search-input.results-hidden .input-group-text {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.custom-search-input.results-hidden .form-control {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.custom-search-input.results-shown .input-group-text {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 0;
    background-color: var(--primary-highlight);
}

.custom-search-input.results-shown .form-control {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 0;
    background-color: var(--primary-highlight);
}

.search-spinner {
    z-index: 10;
}


.theme-toggle {
    cursor: pointer;
}

.theme-switch {
    position: relative;
    width: 50px;
    height: 26px;
    border-radius: 15px;
    background: #ccc;
    overflow: hidden;
    display: flex;
    align-items: center;
    box-shadow: 2px 2px 5px rgb(0, 0, 0, 0.4);
}

.theme-switch-slider {
    position: absolute;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: white;    
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    transition: left 0.3s ease;
}

.theme-switch.dark .theme-switch-slider {
    left: 25px;
}

.theme-switch.dark {
    background: #555;
    box-shadow: 2px 2px 5px rgb(255, 255, 255, 0.4);
}

.theme-switch-slider.dark {
    background: var(--primary);
}

body.dark-mode .dark-input::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

body.light-mode .dark-input::placeholder {
    color: rgba(0, 0, 0, 0.5);
}

body.light-mode .SongCover img {
    box-shadow: 0px 0px 40px 4px rgba(46, 46, 46, 0.1);
}

.reset-button-tooltip {
    position: absolute;
    top: 5px;
    right: 0px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 14px;
    color: var(--text-color);
}

.truncate-result {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.TopbarLoginRegister {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0px 16px;
}

.MenuIcon {
    cursor: pointer;
    font-size: 1.25rem;
    padding: 10px;
}

.MenuIcon.rotated {
    transform: rotate(90deg);
}

.logout-link,
.login-link,
.register-link {
    color: var(--text-color);
    text-decoration: none;
    font-size: 1.1em;
    padding: 0.5rem 1.0rem;
    border: 1px solid var(--secondary);
    background-color: var(--primary);
    border-radius: 10px;
    transition: color 0.2s ease-in-out, border 0.2s ease-in-out;
}

.logout-link:hover,
.login-link:hover,
.register-link:hover {
    color: var(--text-color-highlight);
    border: 1px solid var(--secondary-highlight);
}


@media only screen and (max-width: 768px) {
    .SearchbarContainer, .Topbar {
        width: 100vw;
        transition: padding 0.3s ease;
    }
    .TopbarSearchbar {
        width: 100%;
        margin: 10%;
        transition: width 0.3s ease, margin 0.3s ease;
    }
    .Topbar-logotext {
        margin-left: 0;
    }

    .Topbar-logotext, .theme-toggle {
        opacity: 1;
        max-width: 100%;
    }
    
    .Topbar.search-focused .Topbar-logotext,
    .Topbar.search-focused .theme-toggle,
    .Topbar.search-focused .MenuIcon {
        opacity: 0;
        max-width: 0;
    }
    
    .Topbar.search-focused .TopbarSearchbar {
        width: 100%;
        margin: 2%;
        transition: width 0.3s ease, margin 0.3s ease;
    }

    .Topbar.search-focused .MenuIcon {
        padding: 0px;
    }
    
    .Topbar.search-focused {
        padding-left: 0.5em;
        padding-right: 0.5em;
    }

    .hidden {
        display: none;
    }

    .TopbarLoginRegister {
        margin: 0px 8px;        
    }

    .logout-link,
    .login-link,
    .register-link {
        padding: 0.5rem 0.5rem;
    } 
}