:root {
    --cc-bg: var(--primary);
    --cc-text: var(--text-color);
    --cc-btn-primary-bg: var(--primary-highlight);
    --cc-btn-primary-hover-bg: var(--secondary);
    --cc-btn-primary-text: var(--text-color-highlight);
    --cc-btn-primary-hover-text: var(--text-color-highlight);
    --cc-btn-secondary-bg: var(--primary-highlight);
    --cc-btn-secondary-hover-bg: var(--secondary);
    --cc-btn-secondary-text: var(--text-color-highlight);
    --cc-btn-secondary-hover-text: var(--text-color-highlight);
    --cc-toggle-bg-off: var(--secondary-highlight);
    --cc-toggle-bg-on: var(--accent);
    --cc-toggle-bg-readonly: var(--text-color);
    --cc-cookie-category-block-bg: var(--primary);
    --cc-cookie-category-block-bg-hover: var(--primary-highlight);
    --cc-section-border: var(--primary-highlight);
    --cc-cookie-table-border: var(--primary-highlight);
    --cc-overlay-bg: var(--primary);
}

.cc-link {
    color: var(--accent-hover) !important;
    text-decoration: underline !important;
}
